<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img src="../../../public/logo.png" alt="" width="120" />
        </b-link>
        <center>
          <b-card-title class="mb-1 font-weight-bolder">
            ONLINE MEDIA MONITORING
          </b-card-title>
        </center>
        <b-card-text class="mb-2 text-justify">
          *Silahkan masukan Username dan Password Anda untuk melanjutkan.
        </b-card-text>

        <!-- FORM LOGIN -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- USERNAME -->
            <b-form-group label-for="username" label="Username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Username"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- PASSWORD -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- REMEMBER ME -->
            <b-form-group class="mt-2">
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- LOGIN BUTTON -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              class="mt-3"
            >
              Login
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <small>
        Online Media Monitoring © 2022 All Rights Reserved.
        <u class="text-primary" v-b-modal.cookie-modal>Kebijakan Cookie.</u>
      </small>
    </div>
    <CookieModal />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CookieModal from "./CookieModal.vue";

export default {
  name: "Login",
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    CookieModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      password: "",
      status: "",
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      // Form Validation
      this.$refs.loginForm.validate().then((success) => {
        // If form validation is successful
        if (success) {
          const formData = new FormData();
          formData.append("username", this.username);
          formData.append("password", this.password);
          // Make Login request using JWT request
          // NOTE: You can use axios.post() instead of JWT service
          useJwt
            .login(formData)
            .then((response) => {
              // `response.data` is response from API which is above mentioned
              const { userData } = response.data;

              // Setting access token in localStorage
              // NOTE: Please check the source code to better understand JWT service
              useJwt.setToken(response.data.accessToken);

              // Setting refresh token in localStorage
              useJwt.setRefreshToken(response.data.refreshToken);

              // Setting user data in localStorage
              localStorage.setItem("userData", JSON.stringify(userData));

              // Updating user ability in CASL plugin instance
              this.$ability.update(userData.ability);

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit(
              //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
              //   userData.extras.eCommerceCartItemsCount,
              // )

              // Redirection after login
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Selamat Datang ${
                        userData.fullName || userData.username
                      }`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Login Berhasil! ${userData.role}`,
                    },
                  });
                });
            })
            .catch((error) => {
              this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
