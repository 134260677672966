<template>
  <b-modal
    id="cookie-modal"
    title="Cookie Policy"
    ok-title="Kembali"
    title-tag="h4"
    ok-variant="primary"
    ok-only
    size="lg"
  >
    <!-- CUSTOM MODAL TITLE -->
    <template #modal-title>
      <h3 class="row" style="margin-left: 15px"><b>Cookie Policy</b></h3>
      <small class="row" style="margin-left: 15px">
        Last Update 27-11-2020
      </small>
    </template>

    <!-- CUSTOM OK BUTTON MODAL -->
    <template #modal-ok>
        Tutup & Terima Cookie
    </template>

    <!-- BODY OF MODAL -->
    <div class="cookie-body px-1 mt-2">
      <h6><b>INTRODUCTION</b></h6>
      <p class="text-justify">
        We may use cookies, web beacons, tracking pixels, and other tracking
        technologies when you visit our website Online Media Monitoring,
        including any other media form, media channel, mobile website, or mobile
        application related or connected there to (collectively, the “Site”) to
        help customize the Site and improve your experience.
      </p>
      <p class="text-justify">
        We reserve the right to make changes to this Cookie Policy at any time
        and for any reason. We will alert you about any changes by updating the
        “Last Updated” date of this Cookie Policy. Any changes or modifications
        will be effective immediately upon posting the updated Cookie Policy on
        the Site, and you waive the right to receive specific notice of each
        such change or modification.
      </p>
      <p class="text-justify">
        You are encouraged to periodically review this Cookie Policy to stay
        informed of updates. You will be deemed to have been made aware of, will
        be subject to, and will be deemed to have accepted the changes in any
        revised Cookie Policy by your continued use of the Site after the date
        such revised Cookie Policy is posted.
      </p>
      <p class="text-justify">
        This Cookie Policy was generated by Termly’s Cookie Consent Manager.
      </p>
      <h6 class="mt-2"><b>USE OF COOKIES</b></h6>
      <p class="text-justify">
        A “cookie” is a string of information which assigns you a unique
        identifier that we store on your computer. Your browser then provides
        that unique identifier to use each time you submit a query to the Site.
        We use cookies on the Site to, among other things, keep track of
        services you have used, record registration information, record your
        user preferences, keep you logged into the Site, facilitate purchase
        procedures, and track the pages you visit. Cookies help us understand
        how the Site is being used and improve your user experience.
      </p>
      <h6 class="mt-2"><b>TYPES OF COOKIES</b></h6>
      <p class="text-justify">
        The following types of cookies may be used when you visit the Site:
      </p>
      <h6 class="mt-1"><b>Analytics Cookies</b></h6>
      <p class="text-justify">
        Analytics cookies monitor how users reached the Site, and how they
        interact with and move around once on the Site. These cookies let us
        know what features on the Site are working the best and what features on
        the Site can be improved.
      </p>
      <h6 class="mt-1"><b>Our Cookies</b></h6>
      <p class="text-justify">
        Our cookies are “first-party cookies”, and can be either permanent or
        temporary. These are necessary cookies, without which the Site won’t
        work properly or be able to provide certain features and
        functionalities. Some of these may be manually disabled in your browser,
        but may affect the functionality of the Site.
      </p>
      <h6 class="mt-1"><b>Personalization Cookies</b></h6>
      <p class="text-justify">
        Personalization cookies are used to recognize repeat visitors to the
        Site. We use these cookies to record your browsing history, the pages
        you have visited, and your settings and preferences each time you visit
        the Site.
      </p>
      <h6 class="mt-1"><b>Security Cookies</b></h6>
      <p class="text-justify">
        Security cookies help identify and prevent security risks. We use these
        cookies to authenticate users and protect user data from unauthorized
        parties.
      </p>
      <h6 class="mt-1"><b>Site Management Cookies</b></h6>
      <p class="text-justify">
        Site management cookies are used to maintain your identity or session on
        the Site so that you are not logged off unexpectedly, and any
        information you enter is retained from page to page. These cookies
        cannot be turned off individually, but you can disable all cookies in
        your browser.
      </p>
      <h6 class="mt-1"><b>Third-Party Cookies</b></h6>
      <p class="text-justify">
        Third-party cookies may be place on your computer when you visit the
        Site by companies that run certain services we offer. These cookies
        allow the third parties to gather and track certain information about
        you. These cookies can be manually disabled in your browser.
      </p>
    </div>
  </b-modal>
</template>
  <script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BButton,
  VBModal,
} from "bootstrap-vue";

export default {
  name: "CookieModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BButton,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.cookie-body {
  display: block;
  overflow: scroll;
  height: 35rem;
}
.cookie-body::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.cookie-body::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}

.cookie-body::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.cookie-body::-webkit-scrollbar-corner {
  background-color: #d3eeff;
  border-radius: 10px;
}
</style>